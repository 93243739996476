//Alex - Modules functionality
//My own jQuery ---- ALEX
jQuery(function ($) {
    "use strict";
    $(".card").fadeIn(300);

    var cookie_user = Cookies.get('user');
    $("#username").val(cookie_user);
    $.ajax({
        type: 'get',
        url: '/forcelogout',
        dataType: 'json',
        success: function (data) {
            //
        }
    });
    $("#loginform").on('submit', function (e) {
        e.preventDefault();
        $('#login_submit').prop('disabled', true);
        $('#login_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;");
        var data = $("#loginform").serialize();
        $.ajax({
            type: 'post',
            url: '/',
            data: data,
            dataType: 'json',
            success: function (data) {
                window.open('/index','_self');
            },
            error: function (request, status, error) {
                var errorType = request.responseText;
                $('#login_submit').empty();
                $('#login_submit').html('Log In');
                $('#login_submit').prop('disabled', false);
                $("#login_messages").empty();
                $("#login_messages").append("<div id='login_errors' class='alert alert-danger mt-3' role='alert'><strong>Error: </strong>" + errorType + "</div>");
                $("#login_errors").show();
            }
        });
    });
});